import "./bootstrap";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import Sticky from "jquery-sticky";
// import Alpine from "alpinejs";
import "slick-carousel";
import "lazysizes";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import Swal from "sweetalert2";
// import AOS from "aos";
import "flowbite";
// import "./fullpage";
// import "./fullpage.extensions";
import { CountUp } from "countup.js";
import Swiper from "swiper/bundle";

window.$ = window.jQuery = $;
// window.AOS = AOS;
window.Fancybox = Fancybox;
window.Swal = Swal;
window.Swiper = Swiper;

// window.Alpine = Alpine;
window.sticky = Sticky;
// Initialise jQuery
$(function () {
    // Any jQuery scripts...
    // AOS.init({
    // once: true,
    // });
    let today = new Date();
    $(".inp_date").datepicker({
        dateFormat: "dd/mm/yy",
        maxDate: today,
        changeMonth: true,
        changeYear: true,
        yearRange: "c-100:" + today.getFullYear(),
    });
    var counter = $(".counter");
    counter.each(function (index) {
        // Find the value we want to animate (what lives inside the p tags)
        var value = $(counter[index]).html();
        // Start animating
        var statisticAnimation = new CountUp(counter[index], value, {
            enableScrollSpy: true,
            separator: ".",
            decimal: ",",
            suffix: "+",
        });
        statisticAnimation.start();
    });
    Fancybox.bind("[data-fancybox]", {});

    Fancybox.bind("[data-fancybox-hide]", {
        closeButton: "",
    });
});
